import cn from 'classnames';
import React, { useMemo } from 'react';

import { EListingTileVariant, ListingTile } from '@/components/ui/ListingTile';
import { breakpoint } from '@/constants/breakpoint';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useWindowSize from '@/hooks/useWindowSize';
import { EListingSource } from '@/services/analytics/listings/types';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import css from './Carousel.module.css';

interface ISimilarRentalsProps {
  rentalsList: IRentalTile[];
  maxRentals?: number;
  verticalScrollGrid?: boolean;
  maxCols: number | undefined;
  inline: boolean;
  loading: boolean;
  title: string | undefined;
  extraQueryParams?: string;
  onClickRental: (rental: IRentalTile, index: number) => void;
  onChangeImage: (rental: IRentalTile, index: number, nextIndex: number) => void;
  isRow?: boolean;
  eventSource?: EListingSource;
}

const Carousel: React.FC<ISimilarRentalsProps> = ({
  inline = false,
  rentalsList,
  loading,
  title,
  extraQueryParams,
  onClickRental,
  onChangeImage,
  maxRentals = 3, //mobile only
  verticalScrollGrid,
  maxCols,
  isRow,
  eventSource,
}) => {
  const { isAboveDesktop, isAboveTablet } = useBreakpoint();
  const windowWidth = useWindowSize()?.width || 0;
  const mobileVerticalGrid = verticalScrollGrid && windowWidth < breakpoint.md;

  const superhostBadgeEnabled = useExperimentIsEnabled(OptimizelyFlags.SUPERHOST_BADGE);

  // Dynamically determine the number of rentals to display based on maxCols and screen size
  const adjustedMaxRentals = mobileVerticalGrid
    ? maxRentals
    : isAboveTablet && maxCols
      ? maxCols * (isAboveDesktop ? 4 : 3)
      : rentalsList.length;

  const updatedRentalsList = useMemo(
    () => rentalsList.slice(0, adjustedMaxRentals),
    [adjustedMaxRentals, rentalsList],
  );

  const tilesGridClassName = cn('pt-0 pb-6 grid gap-5 md:overflow-x-hidden', css.carouselGrid, {
    [css.carouselLargeGrid as string]: inline && updatedRentalsList.length === 5,
    [css.carouselInline as string]: inline && updatedRentalsList.length !== 5,
    [css.verticalScrollGrid as string]: verticalScrollGrid,
    ['overflow-x-hidden']: verticalScrollGrid,
    ['overflow-y-hidden overflow-x-scroll']: !verticalScrollGrid,
    [`grid-cols-${maxCols}`]: maxCols && isAboveDesktop, // Adjust grid columns in desktop
    ['!grid-cols-1']: isRow,
  });

  if (loading) {
    return (
      <div
        className={tilesGridClassName}
        style={{ '--max-cols': adjustedMaxRentals } as React.CSSProperties}>
        {[...Array(mobileVerticalGrid ? maxRentals : updatedRentalsList.length || 4)].map(
          (_, i) => (
            <ListingTile key={i} rentalTile={{ loading: true }} />
          ),
        )}
      </div>
    );
  }

  if (!updatedRentalsList?.length) return null;

  return (
    <>
      {title && (
        <h2 data-testid="carousel-title" className="mb-5 autoType800 highlight">
          {title}
        </h2>
      )}

      <div
        data-testid="similar-rentals-carousel"
        className={tilesGridClassName}
        style={{ '--max-cols': adjustedMaxRentals } as React.CSSProperties}>
        {updatedRentalsList.map((rental, index) => (
          <div key={rental.linkUrl}>
            <ListingTile
              rentalTile={{
                ...rental,
                linkUrl: extraQueryParams
                  ? `${rental.linkUrl}?${extraQueryParams}`
                  : rental.linkUrl,
              }}
              onSlideChange={nextIndex => onChangeImage(rental, index, nextIndex)}
              target={isAboveDesktop ? '_blank' : undefined}
              onClick={() => onClickRental(rental, index)}
              showSuperhost={superhostBadgeEnabled}
              dataTestId="listing-tile"
              variant={
                isRow ? EListingTileVariant.HorizontalBorderless : EListingTileVariant.Vertical
              }
              wishlistingEventData={{
                index,
                eventSource,
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Carousel;
